.bg-custom-navbar {
  background: linear-gradient(to bottom, #a9070c, #ef3e43);
}

.bg-custom-navbar2 {
  background: #a9070c;
}

.fixed {
  position: fixed;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.z-40 {
  z-index: 40;
}

.p-5 {
  padding: 20px;
}

.bg-white {
  background-color: #a9070c;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(245, 1, 1, 0.767);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.transition-transform {
  transition: transform 0.3s ease-in-out;
}

.transform {
  transform: translateX(0);
}

.translate-x-full {
  transform: translateX(100%);
}

.translate-x-0 {
  transform: translateX(0);
}
